// External
import type { ComponentType, ReactNode } from 'react';

import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

// Internal
import { experimentsAtom, featuresAtom } from '~jotai/atoms/decision';
import userAtom from './atoms/user';

// Types
import type { DecisionAtom } from '~jotai/atoms/decision';
import type { UserAtom } from './atoms/user';

// Types
export type JotaiHydrationProps = {
  experiments?: DecisionAtom['experiments'];
  features?: DecisionAtom['features'];
  user?: UserAtom;
};

export type HydrateAtomsProps = {
  children: ReactNode;
  value?: JotaiHydrationProps;
};

// Components
// Only hydrate 'globally useful' atoms here
// if your page/component needs to hydrate its own atoms, call useHydrateAtoms there
const HydrateAtoms = ({ children, value }: HydrateAtomsProps) => {
  useHydrateAtoms([
    [experimentsAtom, value?.experiments ?? []],
    [featuresAtom, value?.features ?? []],
    [userAtom, value?.user ?? {}],
  ]);

  return <>{children}</>;
};

const JotaiProvider: ComponentType<HydrateAtomsProps> = ({
  children,
  value,
}) => (
  <Provider>
    <HydrateAtoms value={value}>{children}</HydrateAtoms>
  </Provider>
);

export default JotaiProvider;
