import React from 'react';
import type { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { FallbackProps } from 'react-error-boundary';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import ButtonPrimary from '@taskrabbit/meadow-web/lib/ButtonPrimary';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import { reportError } from '~utils/helpers';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Stack role="alert">
    <Typography>Something went wrong:</Typography>
    <Typography component="pre">{error.message}</Typography>
    <ButtonPrimary onClick={resetErrorBoundary}>Try again</ButtonPrimary>
  </Stack>
);

const errorHandler = (error: Error) => {
  reportError(error);
};

const TRErrorBoundary = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary fallbackRender={ErrorFallback} onError={errorHandler}>
    {children}
  </ErrorBoundary>
);

export default TRErrorBoundary;
