// External
import { useRouter } from 'next/router';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import ButtonImpact from '@taskrabbit/meadow-web/lib/ButtonImpact';
import Stack from '@taskrabbit/meadow-web/lib/Stack';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import type { SelectChangeEvent } from '@taskrabbit/meadow-web/lib/Dropdown';
import Dropdown from '@taskrabbit/meadow-web/lib/Dropdown';

// Internal
import type { PreviewDataProps } from '~pages/api/preview.page';

import { LOCALES } from '~lang/locales';
import getDomain from '~utils/getDomain';

export interface ExitPreviewModeBannerProps {
  previewData?: PreviewDataProps;
}

const BannerContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.meadow.purpose.error.subtle,
  padding: theme.spacing(1),
}));

const ExitPreviewModeBanner = ({ previewData }: ExitPreviewModeBannerProps) => {
  const { asPath, isPreview, locale: currentLocale } = useRouter();

  if (!isPreview) return null;

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    if (!previewData?.sharedToken) return;

    const locale = event.target.value as string;

    const { origin } = getDomain(locale);

    window.location.href = `${origin}/next-api/preview?${new URLSearchParams({
      locale,
      path: asPath,
      shared_token: previewData.sharedToken,
    }).toString()}`;
  };

  return (
    <BannerContainer direction="row" justifyContent="center" spacing={3}>
      <Typography sx={{ alignSelf: 'center', fontWeight: 'bold' }}>
        PREVIEW MODE
      </Typography>
      <ButtonImpact
        href="/next-api/exit-preview"
        size="small"
        sx={{ alignSelf: 'center' }}
      >
        EXIT
      </ButtonImpact>
      <Dropdown
        defaultValue={currentLocale}
        disabled={previewData?.usesEntryLocalization}
        onChange={handleOnChange}
        sx={{ width: '7em' }}
      >
        {LOCALES.map((locale) => (
          <option key={locale} value={locale}>
            {locale}
          </option>
        ))}
      </Dropdown>
    </BannerContainer>
  );
};

export default ExitPreviewModeBanner;
