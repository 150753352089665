// External
import { useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import Box from '@taskrabbit/meadow-web/lib/Box';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import TextButtonPrimary from '@taskrabbit/meadow-web/lib/TextButtonPrimary';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import { setCookie } from 'cookies-next';

// Internal
import { getLocalePrefix } from '~lang/locales';
import { getNextLocale, parseLocale } from '~utils/helpers';

export interface LocaleSwitcherProps {
  country: 'CA' | 'ES';
}

const LocaleSwitcherComp = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    display: 'flex',
    height: '57px',
    lineHeight: spacing(3),
    paddingBottom: spacing(2),
    paddingTop: spacing(2),

    [breakpoints.up('md')]: {
      display: 'inline-flex',
      marginLeft: spacing(6),
      paddingBottom: spacing(0),
      paddingTop: spacing(0),
      position: 'relative',
      zIndex: 1,
    },
  })
);

const LocaleSwitcherMenuContainer = styled('ul')(
  ({
    theme: {
      breakpoints,
      meadow: {
        purpose: { borderRadius, boxShadow },
      },
    },
  }) => ({
    alignItems: 'start',
    background: 'none',
    borderRadius: borderRadius.floating,
    boxShadow: boxShadow.floating,
    display: 'inline-flex',
    flexDirection: 'column',
    paddingLeft: 0,
    position: 'absolute',
    top: 65,
    width: 178,

    [breakpoints.up('md')]: {
      left: -20,
      top: 70,
    },
  })
);

const LocaleSwitcherMenuItem = styled('li')<{
  $language: string;
  $languageCode: string;
}>(
  ({
    $language,
    $languageCode,
    theme: {
      meadow: {
        purpose: { background, borderRadius, primary },
      },
      spacing,
    },
  }) => ({
    '&:active': {
      backgroundColor: primary.main,
    },
    '&:first-of-type': {
      borderTopLeftRadius: borderRadius.floating,
      borderTopRightRadius: borderRadius.floating,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: borderRadius.floating,
      borderBottomRightRadius: borderRadius.floating,
    },
    '&:not(:active):hover': {
      backgroundColor: background.extraEmphasis,
    },
    alignItems: 'center',
    backgroundColor: background.main,
    cursor: 'pointer',
    display: 'flex',
    paddingBottom: spacing(1.25),
    paddingLeft: $language === $languageCode ? spacing(1.25) : spacing(4.25),
    paddingTop: spacing(1.25),
    width: '100%',
  })
);

export const LocaleSwitcher = ({ country }: LocaleSwitcherProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const router = useRouter();
  const locale = getNextLocale(router);

  const { language } = parseLocale(locale);

  const countryLocales = {
    CA: {
      English: 'en',
      Français: 'fr',
    },
    ES: {
      Castellano: 'es',
      Català: 'ca',
    },
  };

  const countryLanguages = countryLocales[country];

  const handleShowMenu = () => {
    setShowMenu((prevValue) => !prevValue);
  };

  const switchLocale = useCallback(
    (languageCode: string) => () => {
      const currentPath = router.asPath;
      const newLocale = languageCode + '-' + country;

      if (locale !== newLocale) {
        const prefix = getLocalePrefix(newLocale);

        setCookie('last_locale', newLocale, { maxAge: 31536000, path: '/' });

        if (currentPath === '/') {
          window.location.href = prefix || '/';
        } else {
          window.location.href = prefix + currentPath;
        }
      }
    },
    [country, locale, router]
  );

  return (
    <LocaleSwitcherComp sx={{ position: 'relative' }}>
      <TextButtonPrimary
        data-testid="locale-switcher"
        iconLeft="language-globe-16"
        iconRight={showMenu ? 'chevron-16-up' : 'chevron-16-down'}
        onClick={handleShowMenu}
        sx={{ fontSize: '16px', lineHeight: '16px', pr: 2, py: 1.5 }}
      >
        {language?.toUpperCase()}
      </TextButtonPrimary>
      {showMenu && language && (
        <LocaleSwitcherMenuContainer>
          {Object.entries(countryLanguages).map(
            ([languageName, languageCode]) => (
              <LocaleSwitcherMenuItem
                $language={language}
                $languageCode={languageCode}
                data-testid="locale-switcher-menu-item"
                key={`locale-selector-menu-item-${languageCode}`}
                onClick={switchLocale(languageCode)}
                role="option"
              >
                {language === languageCode && <IcoMoon name="check-16" />}
                <Typography sx={{ pl: language === languageCode ? 1 : 0 }}>
                  {languageName}
                </Typography>
              </LocaleSwitcherMenuItem>
            )
          )}
        </LocaleSwitcherMenuContainer>
      )}
    </LocaleSwitcherComp>
  );
};
