// TODO: we need a pre-build transpilation step in order to use this in next.config.js
import { isStaging } from './env';

// Types
import type { Locale } from '~lang/localeDrivenConfig';

import { getLocalePrefix, LOCALE_TO_TLD } from '~lang/locales';

const LOCALE_TO_PREFIX: { [key: Locale]: string } = {
  ...LOCALE_TO_TLD,
  'en-GB': 'uk',
  'en-US': 'us',
};

const getHost = (locale: string) => {
  if (process.env.NODE_ENV === 'production') {
    return isStaging()
      ? `${LOCALE_TO_PREFIX[locale]}-${process.env.TR_STAGING_SUBDOMAIN}.taskrabbit.in`
      : `www.taskrabbit.${LOCALE_TO_TLD[locale]}`;
  }

  return `${LOCALE_TO_PREFIX[locale]}-taskrabbit-dev.localhost`;
};

const getDomain = (locale: string) => {
  const host = getHost(locale);

  let protocol = 'http://';
  let port = process.env.PORT ?? '4200';

  if (process.env.NODE_ENV === 'production') {
    protocol = 'https://';
    port = '';
  }

  return {
    host,
    origin: `${protocol}${host}${port ? ':' + port : ''}`,
    pathname: getLocalePrefix(locale),
    protocol,
  };
};

export default getDomain;
