// External
import type { ReactNode } from 'react';

// Internal
import JotaiProvider from '~jotai/provider';
import type { JotaiHydrationProps } from '~jotai/provider';
import type { PreviewDataProps } from '~pages/api/preview.page';
import SiteHeader from '~components/layout/SiteHeader';
import type { MenuItems } from '~components/layout/SiteHeader';
import type { GetLayout } from './types';
import { IsAuthenticatedProvider } from '../contexts/IsAuthenticated';
import { Vendors } from '../vendors';
import Alerts from '../components/Alerts';
import DevInfo, { getDevInfoProps } from '../components/DevInfo';
import type { FooterItems } from '~components/SiteFooter';
import SiteFooter from '~components/SiteFooter';
import ExitPreviewModeBanner from '~components/ExitPreviewModeBanner';

// Styles
export interface DefaultLayoutProps {
  children: ReactNode;
  footerItems?: FooterItems;
  jotai?: JotaiHydrationProps;
  menuItems?: MenuItems;
  previewData?: PreviewDataProps;
}

export const DefaultLayout = ({
  children,
  menuItems,
  footerItems,
  jotai,
  previewData,
}: DefaultLayoutProps) => (
  <JotaiProvider value={jotai}>
    <IsAuthenticatedProvider>
      <SiteHeader menuItems={menuItems} />
      <ExitPreviewModeBanner previewData={previewData} />
      <Alerts />
      <main>{children}</main>
      <SiteFooter footerItems={footerItems} />
      <DevInfo {...getDevInfoProps()} />
    </IsAuthenticatedProvider>
  </JotaiProvider>
);

export const getLayout: GetLayout<DefaultLayoutProps> = (
  Component,
  pageProps
) => (
  <DefaultLayout {...pageProps}>
    <Component {...pageProps} />
    <Vendors />
  </DefaultLayout>
);

export default getLayout;
