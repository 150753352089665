// External
import { Inter } from 'next/font/google';
import localFont from 'next/font/local';

// Webfonts
const inter = Inter({
  display: 'block',
  subsets: ['latin'],
  variable: '--next-font-inter',
});

const icomoon = localFont({
  display: 'block',
  src: '../assets/fonts/icomoon/icomoon.woff',
  variable: '--next-font-icomoon',
});

const ivyPrestoHeadline = localFont({
  display: 'block',
  src: '../assets/fonts/IvyPrestoHeadline/IvyPrestoHeadline-SemiBold.otf',
  variable: '--next-font-ivy-presto-headline',
});

export const rootClassName = `${inter.variable} ${icomoon.variable} ${ivyPrestoHeadline.variable}`;
export const rootFontFamily = `${inter.style.fontFamily}, Helvetica, sans-serif`;
