import localizedPaths from './localizedPaths.json';
import urlTemplate from 'url-template';

// Types

export type LocalizedPathsKeys =
  | 'press_index'
  | 'press_release'
  | 'press_releases'
  | 'privacy'
  | 'terms'
  | 'legal';

export type LocalizedPaths = Record<
  LocalizedPathsKeys,
  {
    [locale: string]: string | undefined;
  }
>;

// Constants

const LOCALIZED_PATHS: LocalizedPaths = localizedPaths;

// Impl
export const getLocalizedPath = (
  locale: string,
  key: LocalizedPathsKeys,
  params?: { [key: string]: string | number }
) => {
  const path = LOCALIZED_PATHS[key][locale];

  if (!path)
    throw new Error(`Localized path for ${key} and ${locale} does not exits.`);

  if (params) {
    const pathTemplate = urlTemplate.parse(path);
    return pathTemplate.expand({ ...params, locale });
  }

  return path;
};
