/**
 * Setup ported from V3 usage of Axios. I expect this will change over time.
 *
 * https://github.com/taskrabbit/v3/blob/fc819f7/packages/tr-web/apps/web/src/util/xhr/configure-axios.ts
 * https://github.com/taskrabbit/v3/blob/fc819f7/packages/tr-web/apps/web/src/util/xhr.ts
 *
 * NOTE: Omitted case change magic (See https://github.com/taskrabbit/v3/pull/9472 - 4/18/19)
 */
// External
import axios from 'axios';
import qs from 'qs';

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { GetServerSidePropsContext } from 'next';

// Internal
import getLocaleConfig from '../../lang';

// Types
export type V3Headers = {
  Accept: string;
  'Accept-Language': string;
  'Content-Type': string;
  'X-COUNTRY-ISO-CODE'?: string;
  'X-CSRF-TOKEN'?: string;
  'X-Requested-With': string;
};

export interface GSSPFetchProps<D> {
  context: GetServerSidePropsContext;
  options?: AxiosRequestConfig<D>;
  reqType?: 'get' | 'post' | 'delete' | 'put';
}

// Helpers
function getHeaders() {
  const { locale } = getLocaleConfig('en-US'); // TODO: use locale getter
  const localeInfo = locale.split('-');

  const headers: V3Headers = {
    Accept: 'application/json',
    'Accept-Language': locale,
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest', // TODO: Unnecessary?
  };

  if (localeInfo.length > 1) {
    headers['X-COUNTRY-ISO-CODE'] = localeInfo[1];
  }

  return headers;
}

// Axios Instance
const axiosInstance: AxiosInstance = axios.create({
  headers: getHeaders(),
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: 'brackets', skipNulls: true }),
  withCredentials: true,
  xsrfHeaderName: 'X-CSRF-Token',
});

// Nextjs GSSP fetcher
export const v3GSSP = <T>(
  url: string,
  { context, reqType = 'get', options = {} }: GSSPFetchProps<T>
): Promise<AxiosResponse<T>> => {
  const { headers, ...restOptions } = options;
  const cookies = Object.entries(context.req.cookies)
    .map(([key, value]) => `${key}=${value ? encodeURIComponent(value) : ''}`)
    .join('; ');

  const axiosFetch = axiosInstance[reqType];
  const axiosOptions = {
    headers: {
      ...headers,
      Cookie: cookies,
    },
    ...restOptions,
  };

  return axiosFetch<T>(url, axiosOptions);
};

export const isAxiosError = axios.isAxiosError;

export type {
  AxiosError,
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse,
} from 'axios';

export default axiosInstance;
