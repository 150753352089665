import ZendeskWidget from './ZendeskWidget';

const Vendors = () => {
  if (process.env.NEXT_PUBLIC_VENDORS_ENABLED === '1') {
    return (
      <>
        <ZendeskWidget />
      </>
    );
  }

  return null;
};

export default Vendors;
