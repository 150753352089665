import axios from '../../utils/axios/v3';

export * from './metrics';

export const METRICS_BUS_URI = '/api/v3/polltime/publish';

export interface MetricBusEventDataType {
  [key: string]: unknown;
  anonymous_id?: string | null;
  client_publish_type: string;
}

class MetricsBusService {
  static fire(eventKey: string, data: object = {}) {
    // Browser only
    if (typeof window !== 'undefined') {
      const eventData: MetricBusEventDataType = {
        anonymous_id: this.getAnonymousId(),
        client_publish_type: eventKey,
        ...data,
      };

      if (typeof window.navigator.sendBeacon === 'function') {
        window.navigator.sendBeacon(METRICS_BUS_URI, JSON.stringify(eventData));
      } else {
        axios.post(METRICS_BUS_URI, eventData);
      }
    }
  }

  static getAnonymousId() {
    if (
      typeof globalThis.analytics !== 'undefined' &&
      typeof globalThis.analytics.user === 'function'
    ) {
      return globalThis.analytics.user().anonymousId();
    }

    const id = localStorage.getItem('ajs_anonymous_id');
    return id ? JSON.parse(id) : null;
  }
}

export default MetricsBusService;
