// External
import React from 'react';

//TODO: Replace direct @mui imports with Meadow Web later (like Box)
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

//Internal
import Box from '@taskrabbit/meadow-web/lib/Box';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import IcoMoon from '@taskrabbit/meadow-web/lib/IcoMoon';
import { menuTextToElem, type MenuItem } from '~components/layout/SiteHeader';

// Types

interface SideMenuItemProps {
  menuItem: MenuItem;
  onClick?: (menuItem: MenuItem) => void;
}

// Styled components

export const ListStyled = styled(List, {
  shouldForwardProp: (prop) => !(prop as string).startsWith('$'),
})<{ $isSubMenu: boolean }>(({ $isSubMenu, theme: { palette } }) => ({
  '.MuiListItem-root': {
    borderTop: !$isSubMenu && `1px solid ${palette.grey[200]}`,
    height: $isSubMenu && '24px',
    marginBottom: $isSubMenu && '12px',
    padding: $isSubMenu && 0,
  },
  '.MuiListItem-root:last-child': {
    borderBottom: !$isSubMenu && `1px solid ${palette.grey[200]}`,
  },
  margin: 0,
  padding: 0,
}));

const ListItemStyled = styled(ListItem)(({ theme: { palette } }) => ({
  height: '57px',
  lineHeight: '24px',
  paddingBottom: '1rem',
  paddingTop: '1rem',
}));

const ListItemButtonStyled = styled(ListItemButton)(
  ({ theme: { palette } }) => ({
    '.MuiIcon-root': {
      color: palette.primary.main,
    },
    color: palette.primary.main,
    cursor: 'pointer',
    fontSize: '1rem',
  })
);

export const SideMenuContainer = styled(Box)(() => ({
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  textAlign: 'right',
}));

export const BackButton = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.primary.main,
  cursor: 'pointer',
  fontWeight: 400,
  height: '24px',
  justifyContent: 'flex-start',
  lineHeight: '24px',
  marginTop: 16,
  textAlign: 'left',
}));

// Components

export const SideMenuItem = ({ menuItem, onClick }: SideMenuItemProps) => {
  const hasSubMenu = !!menuItem.menu;

  const handleOnClick = () => onClick && onClick(menuItem);

  return (
    <ListItemStyled disablePadding>
      <ListItemButtonStyled
        onClick={handleOnClick}
        sx={{
          fontWeight: hasSubMenu ? undefined : 600,
          paddingLeft: '0px',
        }}
      >
        {menuItem.icon && (
          <IcoMoon
            name={menuItem.icon}
            sx={{ fontSize: 16, marginRight: '5px', width: '1rem' }}
          />
        )}
        {menuTextToElem(menuItem.text)}
        {hasSubMenu ? (
          <IcoMoon
            name="lnr-chevron-right"
            sx={{ fontSize: 16, marginLeft: '5px' }}
          />
        ) : null}
      </ListItemButtonStyled>
    </ListItemStyled>
  );
};
