import pino from 'pino';

const logger = pino({
  browser: {
    asObject: true,
  },
  level: !process.env.PINO_LOG_LEVEL ? 'info' : process.env.PINO_LOG_LEVEL,
  messageKey: 'message',
});

export const jsonLogger = pino({
  browser: {
    write: (o) => console.log(JSON.stringify(o)),
  },
  level: !process.env.PINO_LOG_LEVEL ? 'info' : process.env.PINO_LOG_LEVEL,
  messageKey: 'message',
});

export const nextBuildLog = (
  prefix: string,
  message?: string,
  locale?: string,
  id?: string
) => {
  const parts = [];

  let start = `${prefix.padEnd(5).slice(0, 5)}`;

  if (locale) parts.push(`[${locale}]`);
  if (id) parts.push(`<${id}>`);
  if (message) parts.push(message);

  console.log([start, parts.join(' ')].join(' - '));
};

export default logger;
