/**
 * This was necessary to resolve a bug:
 * https://taskrabbit.atlassian.net/browse/CLIFE-245
 *
 * TODO: Revisit after Next.js' static assets are served from CDN
 */

// External
import Image from 'next/image';

// Internal
import SocialFb from '~public/next-images/fb.svg';
import SocialIn from '~public/next-images/insta.svg';
import SocialLi from '~public/next-images/lin.svg';
import SocialTt from '~public/next-images/tiktok.svg';
import SocialTw from '~public/next-images/tw.svg';

export interface SocialIconProps {
  alt: string;
  href?: string;
  type: string;
}

const SocialImages: { [key: string]: any } = {
  fb: SocialFb,
  insta: SocialIn,
  lin: SocialLi,
  tiktok: SocialTt,
  tw: SocialTw,
};

const SocialIcon = ({ alt, href, type }: SocialIconProps) => (
  <a href={href} rel="noreferrer" target="_blank">
    <Image alt={alt} height="24" src={SocialImages[type]} width="24" />
  </a>
);

export default SocialIcon;
