// External
import type { ReactNode, ReactElement } from 'react';
import type {
  IntlShape as reactIntlShape,
  MessageDescriptor,
} from 'react-intl';
import { useIntl as reactUseIntl } from 'react-intl';
import type {
  PrimitiveType,
  Options,
  FormatXMLElementFn,
} from 'intl-messageformat';

// Internal
import USEINTL_COMMON_TAGS from './useIntlCommonTags';

// Types
export interface IntlShape extends reactIntlShape {
  formatRichMessage(
    descriptor: MessageDescriptor,
    values?: Record<
      string,
      PrimitiveType | ReactElement | FormatXMLElementFn<ReactNode>
    >,
    opts?: Options
  ): string | ReactNode | ReactNode[];
}

// Hook
const useIntl = (): IntlShape => {
  const intl = reactUseIntl() as IntlShape;

  intl.formatRichMessage = (
    descriptor: MessageDescriptor,
    values?: Record<
      string,
      PrimitiveType | ReactElement | FormatXMLElementFn<ReactNode>
    >,
    opts?: Options
  ): string | ReactNode | ReactNode[] =>
    intl.formatMessage(
      descriptor,
      {
        ...USEINTL_COMMON_TAGS,
        ...values,
      },
      opts
    );

  return intl;
};

export default useIntl;
