export interface TranslatePath {
  instruction: string;
  key: string;
  path: string;
}

export interface Smartling {
  string_format: string;
  translate_paths: TranslatePath[];
  variants_enabled?: boolean;
}

export interface SmartlingMessage {
  description?: string;
  message: string;
}

export interface SmartlingDocument {
  smartling: Smartling;
  strings: Record<string, SmartlingMessage>[];
}

/**
 * Converts the Smartling Localization Document into key/value pairs consumable by the
 * IntlProvider component
 * */
export function formatter(msgs: SmartlingDocument) {
  const results: Record<string, string> = {};
  const [strings] = msgs.strings;
  for (const key in strings) results[key] = strings[key].message;
  return results;
}
