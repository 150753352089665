// External
import { atom } from 'jotai';

export type UserAtom = {
  email?: string;
  id?: number;
  phoneNumber?: string;
};

const userAtom = atom<UserAtom>({});

export default userAtom;
