// External
import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { useEffectOnce } from 'react-use';

import Head from 'next/head';
import type { AppProps } from 'next/app';

import { getCookie } from 'cookies-next';

import TRThemeProvider from '@taskrabbit/meadow-web/lib/Theme';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

import 'modern-normalize/modern-normalize.css';

import getThemeSierra from '@taskrabbit/meadow-web/lib/Theme/themes/Sierra';

import MeadowDateIo from '@taskrabbit/meadow-web/lib/date-io/MeadowDateIo';

// Internal
import { rootFontFamily } from './webfonts';

import getDateIo from '~utils/getDateIo';
import { guardString } from '~utils/helpers';
import { trpc } from '~utils/trpc';

import getDefaultLayout from '~layouts/Default';
import type { GetLayout } from '~layouts/types';

import getLocaleConfig from '../lang';

import '../assets/fonts/icomoon/style.css';
import './styles.css';

import { AlertProvider } from '../contexts/Alert';
import TRErrorBoundary from '../components/TRErrorBoundary';
import { useRouter } from 'next/router';
import DigiohScript from '~components/marketing/DigiohScript';

if (
  process.env.NODE_ENV !== 'production' &&
  process.env.NEXT_PUBLIC_MSW_API_MOCKING === '1'
) {
  async function importMSWMocks(): Promise<void> {
    const { default: selectServer } = await import('../mocks');
    selectServer();
  }

  importMSWMocks();
}

// Types
interface CustomAppProps {
  Component: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getLayout?: GetLayout<any>;
  };
}

// Styles
const StyledApp = styled('div')(
  ({
    theme: {
      palette: { common },
    },
  }) => ({ backgroundColor: common.white })
);

/** TODO: Move into `TRNextApp` when dark mode is a thing */
const theme = getThemeSierra();

// Root Component
function TRNextApp({ Component, pageProps }: CustomAppProps & AppProps) {
  const { locale } = useRouter();

  const { dateIoAdapter, localeConfig } = useMemo(
    () => ({
      dateIoAdapter: getDateIo(locale),
      localeConfig: getLocaleConfig(locale),
    }),
    [locale]
  );

  const getLayout = Component.getLayout || getDefaultLayout;

  // TODO: Revisit when Google Analytics is integrated with Segment

  // Populating the window object with __guidValueLoggedIn is a temporary
  // solution for Google Tag Manager. __guidValueLoggedIn is used by the GTM
  // variable 'guidLoggedIn' to send guids of logged in users to Google Analytics.
  useEffectOnce(() => {
    const uidCookie = getCookie('uid');

    window.__guidValueLoggedIn =
      uidCookie && getCookie('session') ? guardString(uidCookie) : null;
  });

  return (
    <>
      <style global jsx>{`
        html,
        body {
          font-family: ${rootFontFamily};
        }
      `}</style>
      <Head>
        <title>Taskrabbit</title>
      </Head>
      <MeadowDateIo.Provider value={dateIoAdapter}>
        <TRThemeProvider theme={theme}>
          <StyledApp>
            <IntlProvider {...localeConfig}>
              <AlertProvider>
                <TRErrorBoundary>
                  {getLayout(Component, pageProps)}
                </TRErrorBoundary>
              </AlertProvider>
            </IntlProvider>
          </StyledApp>
        </TRThemeProvider>
      </MeadowDateIo.Provider>
      <DigiohScript locale={locale} />
    </>
  );
}

export default trpc.withTRPC(TRNextApp);
