import { useContext } from 'react';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import type { Palette } from '@mui/material';

import { AlertContext } from '../contexts/Alert';
import type { AlertInstance } from '../contexts/Alert';

interface AlertProps {
  instance: AlertInstance;
}

const MessageComp = styled('div')({
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 300,
});

const alertColor = (palette: Palette, type: AlertInstance['type']) => {
  const paletteColor = type === 'warn' ? 'warning' : type;
  return palette[paletteColor].main;
};

// TODO: Meadow component
const AlertComp = styled('div')<{ $type: AlertInstance['type'] }>(
  ({ theme: { palette }, $type }) => ({
    backgroundColor: alertColor(palette, $type),
    color: palette.common.white,
    padding: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '3rem',
    position: 'relative',
    textAlign: 'center',
    webkitFontSmoothing: 'initial',
  })
);

const Alert = ({ instance: { alert, dismiss, type } }: AlertProps) => (
  <AlertComp
    $type={type}
    data-testid="alert"
    onClick={dismiss}
    onKeyDown={dismiss}
    role="button"
    tabIndex={0}
  >
    {alert}
  </AlertComp>
);

const Alerts = () => {
  const { alerts } = useContext(AlertContext);

  return (
    <MessageComp>
      {alerts.map((instance) => (
        <Alert instance={instance} key={instance.id} />
      ))}
    </MessageComp>
  );
};

export default Alerts;
