export interface Cookies {
  [key: string]: string;
}

// this is a simple parser, consider pulling in something like
// https://www.npmjs.com/package/cookie
// if more functionality is needed in the future
export const parseCookies = (cookieHeader: string | undefined): Cookies => {
  if (!cookieHeader) return {};
  return cookieHeader.split(';').reduce((cookies, cookieStr) => {
    const [key, value] = cookieStr.trim().split('=');
    return { [key]: value, ...cookies };
  }, {});
};
