// External
import React, { useCallback, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

// Constants
const DEFAULT_EXPIRATION_TIME = 7000;

// Types
export type Alert = ReactNode | ReactNode[];

export type AlertType = 'error' | 'info' | 'success' | 'warn';

interface AlertFunc {
  (alerts: Alert, timeout?: number): void;
}

export interface AlertFuncs {
  error: AlertFunc;
  info: AlertFunc;
  success: AlertFunc;
  warn: AlertFunc;
}

export interface AlertInstance {
  alert: ReactNode;
  dismiss: () => void;
  id: number;
  type: AlertType;
}

interface AlertContextType {
  alert: AlertFuncs;
  alerts: AlertInstance[];
}

// Context
let alertIndex = 0;

function createAlert(
  setAlertList: Dispatch<SetStateAction<AlertInstance[]>>,
  alert: Alert,
  timeout: number,
  type: AlertType = 'info'
): AlertInstance {
  const id = (alertIndex += 1);

  const instance: AlertInstance = {
    alert,
    dismiss: () => {
      setAlertList((oldAlerts) => oldAlerts.filter((a) => a.id !== id));
    },
    id,
    type,
  };

  timeout = timeout || DEFAULT_EXPIRATION_TIME;

  if (timeout > 0) {
    setTimeout(() => instance.dismiss(), timeout);
  }

  return instance;
}

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlertList] = useState<AlertInstance[]>([]);

  const addAlert = useCallback(
    (newAlerts: Alert, type: AlertType, timeout: number = 0) => {
      let alertInstances: AlertInstance[];

      if (newAlerts instanceof Array) {
        alertInstances = newAlerts.map((alert) =>
          createAlert(setAlertList, alert, timeout, type)
        );
      } else {
        alertInstances = [createAlert(setAlertList, newAlerts, timeout, type)];
      }

      setAlertList((oldAlerts) => oldAlerts.concat(alertInstances));
    },
    []
  );

  const alertFuncs = useMemo<AlertFuncs>(
    () => ({
      error: (alerts, timeout) => addAlert(alerts, 'error', timeout),
      info: (alerts, timeout) => addAlert(alerts, 'info', timeout),
      success: (alerts, timeout) => addAlert(alerts, 'success', timeout),
      warn: (alerts, timeout) => addAlert(alerts, 'warn', timeout),
    }),
    [addAlert]
  );

  const value: { alert: AlertFuncs; alerts: AlertInstance[] } = useMemo(
    () => ({
      alert: alertFuncs,
      alerts,
    }),
    [alertFuncs, alerts]
  );

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export const AlertContext = React.createContext<AlertContextType>({
  alert: {
    error: () => {},
    info: () => {},
    success: () => {},
    warn: () => {},
  },
  alerts: [],
});
