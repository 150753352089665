/**
 * This was necessary to resolve a bug:
 * https://taskrabbit.atlassian.net/browse/CLIFE-245
 *
 * TODO: Revisit after Next.js' static assets are served from CDN
 */

// External
import Image from 'next/image';

// Internal
import CaEsIos from '~public/next-images/footer/ca-ES/download-ios.svg';
import CaEsAnd from '~public/next-images/footer/ca-ES/google-play.svg';
import DeDeIos from '~public/next-images/footer/de-DE/download-ios.svg';
import DeDeAnd from '~public/next-images/footer/de-DE/google-play.svg';
import EnCaIos from '~public/next-images/footer/en-CA/download-ios.svg';
import EnCaAnd from '~public/next-images/footer/en-CA/google-play.svg';
import EnGbIos from '~public/next-images/footer/en-GB/download-ios.svg';
import EnGbAnd from '~public/next-images/footer/en-GB/google-play.svg';
import EnUsIos from '~public/next-images/footer/en-US/download-ios.svg';
import EnUsAnd from '~public/next-images/footer/en-US/google-play.svg';
import EsEsIos from '~public/next-images/footer/es-ES/download-ios.svg';
import EsEsAnd from '~public/next-images/footer/es-ES/google-play.svg';
import FrCaIos from '~public/next-images/footer/fr-CA/download-ios.svg';
import FrCaAnd from '~public/next-images/footer/fr-CA/google-play.svg';
import FrFrIos from '~public/next-images/footer/fr-FR/download-ios.svg';
import FrFrAnd from '~public/next-images/footer/fr-FR/google-play.svg';
import ItItIos from '~public/next-images/footer/it-IT/download-ios.svg';
import ItItAnd from '~public/next-images/footer/it-IT/google-play.svg';
import PtPtIos from '~public/next-images/footer/pt-PT/download-ios.svg';
import PtPtAnd from '~public/next-images/footer/pt-PT/google-play.svg';

export interface AppStoreIconProps {
  alt: string;
  height: number;
  locale: string;
  type: 'android' | 'ios';
  width: number;
}

const AppStoreIcons: {
  [key: string]: { [key in AppStoreIconProps['type']]: any };
} = {
  'ca-ES': { android: CaEsAnd, ios: CaEsIos },
  'de-DE': { android: DeDeAnd, ios: DeDeIos },
  'en-CA': { android: EnCaAnd, ios: EnCaIos },
  'en-GB': { android: EnGbAnd, ios: EnGbIos },
  'en-US': { android: EnUsAnd, ios: EnUsIos },
  'es-ES': { android: EsEsAnd, ios: EsEsIos },
  'fr-CA': { android: FrCaAnd, ios: FrCaIos },
  'fr-FR': { android: FrFrAnd, ios: FrFrIos },
  'it-IT': { android: ItItAnd, ios: ItItIos },
  'pt-PT': { android: PtPtAnd, ios: PtPtIos },
};

const AppStoreIcon = ({
  alt,
  height,
  locale,
  type,
  width,
}: AppStoreIconProps) => (
  <Image
    alt={alt}
    height={height}
    src={AppStoreIcons[locale][type]}
    width={width}
  />
);

export default AppStoreIcon;
