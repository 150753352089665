// External
import type { ReactNode, FunctionComponent } from 'react';

// Types
export interface ExternalLinkProps {
  children: ReactNode[];
  href: string;
}

// Helpers
const ExternalLink: FunctionComponent<ExternalLinkProps> = ({
  href,
  children,
}: ExternalLinkProps) => (
  <a href={href} rel="noopener noreferrer" target="_blank">
    {children}
  </a>
);

const externalLinkTag = (href: string) =>
  function renderer(...chunks: ReactNode[]) {
    return <ExternalLink href={href}>{chunks}</ExternalLink>;
  };

// Constants
const USEINTL_COMMON_TAGS = {
  b: (...chunks: ReactNode[]) => chunks.map((e, i) => <b key={i}>{e}</b>),
  br: <br />,
  em: (...chunks: ReactNode[]) => chunks.map((e, i) => <em key={i}>{e}</em>),
  'link-to-common-page': externalLinkTag(
    'https://www.taskRabbit.com/common/page'
  ),
  pre: (...chunks: ReactNode[]) =>
    chunks.map((e, i) => (
      <pre className="d-inline text-secondary" key={i}>
        {e}
      </pre>
    )),
};

export default USEINTL_COMMON_TAGS;
