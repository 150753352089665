import React, { useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import { parseCookies } from '../utils/cookies';

// Types
export interface IsAuthenticatedProviderProps {
  children: ReactNode;
}

// Context
export const IsAuthenticatedContext = React.createContext(false);

// Provider
export const IsAuthenticatedProvider = ({
  children,
}: IsAuthenticatedProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const cookies = parseCookies(document.cookie);

    setIsAuthenticated(!!cookies.session);
  }, []);

  return (
    <IsAuthenticatedContext.Provider value={isAuthenticated}>
      {children}
    </IsAuthenticatedContext.Provider>
  );
};
