// External
import FormattedMessage from './FormattedMessage';
import Image from 'next/legacy/image';
import styled from '@taskrabbit/meadow-web/lib/Theme/styled';

// Internal
import useIntl from '~hooks/useIntl';
import americanExpressSvg from '../public/next-images/american_express.svg';
import defaultCardSvg from '../public/next-images/default_card.svg';
import dinersClubSvg from '../public/next-images/diners_club.svg';
import discoverSvg from '../public/next-images/discover.svg';
import jcbSvg from '../public/next-images/jcb.svg';
import mastercardSvg from '../public/next-images/mastercard.svg';
import sepaSvg from '../public/next-images/sepa.svg';
import visaSvg from '../public/next-images/visa.svg';

// Types
export interface PaymentMethodInfoProps {
  cardType: string | null;
  last4: string;
  paymentMethodType: keyof typeof imgSrcByPaymentMethodType;
}
export interface ImgSrcByPaymentMethodType {
  [key: string]:
    | {
        [key: string]: string;
      }
    | undefined;
}

// Styled
const PaymentMethodInfoStyled = styled('span')`
  display: flex;
  align-items: center;
`;

// Constants
const imgSrcByPaymentMethodType: ImgSrcByPaymentMethodType = {
  card: {
    amex: americanExpressSvg,
    diners: dinersClubSvg,
    discover: discoverSvg,
    jcb: jcbSvg,
    mastercard: mastercardSvg,
    visa: visaSvg,
  },
  sepa_debit: {
    null: sepaSvg,
  },
};

const PaymentMethodInfo = ({
  cardType = null,
  last4,
  paymentMethodType,
}: PaymentMethodInfoProps) => {
  const intl = useIntl();

  const getImageAlt = () =>
    cardType ||
    intl.formatRichMessage({
      id: `payments.displayInfo.imgAlt.${paymentMethodType}`,
    });

  const getImgSrc = () => {
    const convertedCardType = String(cardType) as
      | keyof ImgSrcByPaymentMethodType['card']
      | keyof ImgSrcByPaymentMethodType['sepa_debit'];
    return (
      imgSrcByPaymentMethodType[paymentMethodType]?.[convertedCardType] ??
      defaultCardSvg
    );
  };

  const renderImg = () => (
    <Image
      alt={getImageAlt() as string}
      height={35}
      src={getImgSrc()}
      width={50}
    />
  );

  return (
    <PaymentMethodInfoStyled>
      {renderImg()}
      <FormattedMessage
        id={`payments.displayInfo.last4.${paymentMethodType}`}
        values={{ last4 }}
      />
    </PaymentMethodInfoStyled>
  );
};

export default PaymentMethodInfo;
