import { formatter } from './smartlingFormatter';

// Types
export interface LocaleConfig {
  locale: string;
  messages: Record<string, string>;
}

// Constants
export const defaultLocale = 'en-US';

// Locales
export const guardLocale = (locale?: string): string => locale || defaultLocale;

const getLocaleConfig = (locale: string = defaultLocale): LocaleConfig => {
  try {
    const messages = require(`./locales/${locale}.json`);
    return {
      locale,
      messages: formatter(messages),
    };
  } catch (error) {
    if (error instanceof Error) {
      if (!error.message.toLowerCase().includes('cannot find module')) {
        throw error;
      }
    }
    const messages = require(`./locales/${defaultLocale}.json`);

    return {
      locale: defaultLocale,
      messages: locale === 'en-US' ? formatter(messages) : messages,
    };
  }
};

export default getLocaleConfig;
