// External
import Script from 'next/script';

// Internal
import { localeDrivenConfig } from '~lang/localeDrivenConfig';

const SCRIPT_URL =
  'https://www.lightboxcdn.com/vendor/4921a0e7-128c-4d67-8262-48575b9dd75f/lightbox_speed.js';

// Digioh Script
const getDigiohScript = (locale: string) => {
  const countryBlockedByGDPR = localeDrivenConfig(locale, 'legal.gdpr.enabled');

  // Only render the JS script when we know for sure that we are NOT blocked by GDPR.
  if (countryBlockedByGDPR === false) {
    return { src: SCRIPT_URL, type: 'text/javascript' };
  }

  return {
    class: 'optanon-category-C000',
    src: SCRIPT_URL,
    type: 'text/plain',
  };
};

// Component
const DigiohScript = ({ locale }: { locale: string | undefined }) => {
  if (process.env.NODE_ENV !== 'production' || !locale) return null;

  return <Script {...getDigiohScript(locale)} strategy="lazyOnload" />;
};

export default DigiohScript;
