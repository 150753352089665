// External
import Link from 'next/link';

import styled from '@taskrabbit/meadow-web/lib/Theme/styled';
import Typography from '@taskrabbit/meadow-web/lib/Typography';
import Divider from '@taskrabbit/meadow-web/lib/Divider';

export const FooterAnchorComp = styled('a')(({ theme }) => ({
  color: theme.meadow.purpose.text.invert,
  fontWeight: 600,
  textDecoration: 'none',
}));

export const FooterNextLinkComp = styled(Link)(({ theme }) => ({
  color: theme.meadow.purpose.text.invert,
  fontWeight: 600,
  textDecoration: 'none',
}));

export const InvertedDividerComp = styled(Divider)(({ theme }) => ({
  borderColor: theme.meadow.purpose.text.invert,
}));

export const InvertedTypographyComp = styled(Typography)(({ theme }) => ({
  color: theme.meadow.purpose.text.invert,
}));

const SiteFooterComp = styled('footer')(({ theme: { spacing } }) => ({
  background: '#67727e',
  padding: spacing(3, 0),
}));

export default SiteFooterComp;
