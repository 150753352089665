// External
import { atom } from 'jotai';

// Internal
import type { Experiment } from '~services/Decision';

export type DecisionAtom = {
  experiments: Experiment[];
  features: string[];
};

export const experimentsAtom = atom<DecisionAtom['experiments']>([]);
export const featuresAtom = atom<DecisionAtom['features']>([]);

const decisionAtom = atom<DecisionAtom>((get) => ({
  experiments: get(experimentsAtom),
  features: get(featuresAtom),
}));

export default decisionAtom;
