export interface LocaleDrivenConfig {
  'book.confirm.priceDetails': string;
  'book.recommendations.idBanner.backgroundChecks': boolean;
  'legal.gdpr.enabled': boolean;
  'signup.explicitMarketingConsent': boolean;
  'signup.explicitTermsConsent': boolean;
  'signup.PrivacyUrl': string;
  'signup.termsUrl': string;
  'social.facebook.url': string;
  'social.instagram.url': string;
  'social.linkedin.url': string;
  'social.tiktok.url': string;
  'social.twitter.url': string;
  'support.cancellationPolicyUrl': string;
  'support.covidUpdatesUrl': string;
  'support.trustAndSafetyUrl': string;
  'support.trustAndSupportFeeUrl': string;
}

// These are the same for all locales. Leaving them in this file
// allows us to change them per locale in the future if needed.
const facebookUrl = 'https://www.facebook.com/taskrabbit/';
const instagramUrl = 'https://www.instagram.com/taskrabbit/';
const linkedInUrl = 'https://www.linkedin.com/company/taskrabbit';
const tikTokUrl = 'https://www.tiktok.com/@taskrabbit';
const twitterUrl = 'https://twitter.com/taskrabbit';

const localeConfig: {
  readonly [key: string]: LocaleDrivenConfig | undefined;
} = {
  'ca-ES': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/es/ca/privadesa',
    'signup.termsUrl': '/es/ca/condicions/terms-ca-es-v20200102',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/ca/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/ca/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/ca/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/en-us/articles/204940570',
  },
  'de-DE': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/datenschutz',
    'signup.termsUrl': '/geschaeftsbedingungen/terms-de-de-v20190920',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/de/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/de/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/de/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/de/articles/204940570',
  },
  'en-CA': {
    'book.confirm.priceDetails': 'trustAndSupportFeeSalesTaxMessage',
    'book.recommendations.idBanner.backgroundChecks': true,
    'legal.gdpr.enabled': false,
    'signup.explicitMarketingConsent': false,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/ca/en/privacy',
    'signup.termsUrl': '/ca/en/terms/terms-en-ca-v20201210',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/en-ca/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/en-ca/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/en-ca/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/en-ca/articles/204940570',
  },
  'en-GB': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/privacy',
    'signup.termsUrl': '/terms/terms-en-gb-v20191218',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/en-gb/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/en-gb/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/en-gb/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/en-gb/articles/204940570',
  },
  'en-US': {
    'book.confirm.priceDetails': 'finePrint',
    'book.recommendations.idBanner.backgroundChecks': true,
    'legal.gdpr.enabled': false,
    'signup.explicitMarketingConsent': false,
    'signup.explicitTermsConsent': false,
    'signup.PrivacyUrl': '/privacy',
    'signup.termsUrl': '/terms',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/en-us/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/en-us/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/en-us/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/en-us/articles/204940570',
  },
  'es-ES': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/es/es/privacidad',
    'signup.termsUrl': '/es/es/condiciones/terms-es-es-v20200102',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/es-es/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/es-es/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/es-es/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/es-es/articles/204940570',
  },
  'fr-CA': {
    'book.confirm.priceDetails': 'trustAndSupportFeeSalesTaxMessage',
    'book.recommendations.idBanner.backgroundChecks': true,
    'legal.gdpr.enabled': false,
    'signup.explicitMarketingConsent': false,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/ca/fr/informations-personnelles',
    'signup.termsUrl': '/ca/fr/conditions-generales/terms-fr-ca-v20201210',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/fr-ca/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/fr-ca/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/fr-ca/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/fr-ca/articles/204940570',
  },
  'fr-FR': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/informations-personelles',
    'signup.termsUrl': '/conditions-generales/terms-fr-fr-v20201210',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/fr-fr/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/fr-fr/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/fr-fr/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/fr-fr/articles/204940570',
  },
  'it-IT': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/privacy',
    'signup.termsUrl': '/termini-di-servizio/terms-it-it-v20210113',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/it/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/it/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/it/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/it/articles/204940570',
  },
  'pt-PT': {
    'book.confirm.priceDetails': 'finePrintVat',
    'book.recommendations.idBanner.backgroundChecks': false,
    'legal.gdpr.enabled': true,
    'signup.explicitMarketingConsent': true,
    'signup.explicitTermsConsent': true,
    'signup.PrivacyUrl': '/privacidade',
    'signup.termsUrl': '/termos/terms-pt-pt-v20200624',
    'social.facebook.url': facebookUrl,
    'social.instagram.url': instagramUrl,
    'social.linkedin.url': linkedInUrl,
    'social.tiktok.url': tikTokUrl,
    'social.twitter.url': twitterUrl,
    'support.cancellationPolicyUrl':
      'https://support.taskrabbit.com/hc/pt/articles/360035010972',
    'support.covidUpdatesUrl':
      'https://support.taskrabbit.com/hc/pt/articles/360040752692',
    'support.trustAndSafetyUrl':
      'https://support.taskrabbit.com/hc/pt/articles/207813543-Overview-of-Trust-and-Safety',
    'support.trustAndSupportFeeUrl':
      'https://support.taskrabbit.com/hc/pt/articles/204940570',
  },
};

export type Locale = keyof typeof localeConfig;
/**
 * Utility to access values from the localeDrivenConfig object
 * @param locale - BCP 47 language tag
 * @param key - token to access value from object
 */
export const localeDrivenConfig = <K extends keyof LocaleDrivenConfig>(
  locale: Locale,
  key: K
) => {
  return localeConfig[locale]?.[key];
};
